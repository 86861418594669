import { takeEvery } from "redux-saga/effects";

import * as actionTypes from "../actions/actionTypes";
import { authUserRegister, authUserLogin } from "./authSaga";

import {
  fetchNews,
  fetchBooks,
  fetchHomeNews,
  fetchHomeBooks,
  getReaderToken,
  fetchNewsEditions,
  fetchSingleNews,
  fetchSingleBook,
  fetchFavorites,
  fetchSubs,
  addFavorite,
  addSub,
  removeFavorite,
} from "./fetchDataSaga";

export function* watchSaga() {
  yield takeEvery(actionTypes.AUTH_REGISTER, authUserRegister);
  yield takeEvery(actionTypes.AUTH_LOGIN, authUserLogin);
  yield takeEvery(actionTypes.FETCH_NEWS, fetchNews);
  yield takeEvery(actionTypes.FETCH_BOOKS, fetchBooks);
  yield takeEvery(actionTypes.GET_READER_TOKEN, getReaderToken);
  yield takeEvery(actionTypes.FETCH_HOME_NEWS, fetchHomeNews);
  yield takeEvery(actionTypes.FETCH_HOME_BOOKS, fetchHomeBooks);
  yield takeEvery(actionTypes.FETCH_NEWS_EDITIONS, fetchNewsEditions);
  yield takeEvery(actionTypes.FETCH_SINGLE_NEWS, fetchSingleNews);
  yield takeEvery(actionTypes.FETCH_SINGLE_BOOK, fetchSingleBook);
  yield takeEvery(actionTypes.GET_FAVORITES, fetchFavorites);
  yield takeEvery(actionTypes.GET_SUBS, fetchSubs);
  yield takeEvery(actionTypes.ADD_SUB, addSub);
  yield takeEvery(actionTypes.ADD_FAVORITE, addFavorite);
  yield takeEvery(actionTypes.REMOVE_FAVORITE, removeFavorite);
}
