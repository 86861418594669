import { combineReducers } from "redux";

import {
  user,
  loginFormStatus,
  registerFormStatus,
  authErrorMessages,
} from "./auth";
import {
  news,
  homeNews,
  selectedCategoryNews,
  singleNews,
  newsEditions,
  singleNewsObject,
} from "./news";
import {
  books,
  homeBooks,
  selectedCategoryBooks,
  singleBook,
  singleBookObject,
} from "./books";
import { favorites, subs } from "./shared";
import { fileReaderStatus, file } from "./fileReader";

const rootReducer = combineReducers({
  user,
  loginFormStatus,
  registerFormStatus,
  news,
  homeNews,
  books,
  homeBooks,
  selectedCategoryNews,
  selectedCategoryBooks,
  fileReaderStatus,
  file,
  singleBook,
  singleNews,
  authErrorMessages,
  newsEditions,
  singleNewsObject,
  singleBookObject,
  favorites,
  subs,
});

export default rootReducer;
