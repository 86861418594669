import { put, call } from "redux-saga/effects";

import * as actions from "../actions/index";
import * as api from "../api";

export function* authUserRegister(action) {
  const authData = {
    email: action.email,
    password: action.password,
    password_confirmation: action.password_confirm,
  };

  try {
    const response = yield call(api.registerUser, authData);
    //console.log("Response register user: ", response);

    if (response.errors) {
      console.log("Register errors response: ", response.errors);
      yield put(
        actions.setErrorAuthMessages(
          response.errors.data.email,
          response.errors.data.password,
          ""
        )
      );
    } else {
      yield put(actions.authLogin(action.email, action.password));
      yield put(actions.setErrorAuthMessages("", "", ""));
      yield put(actions.authSuccess(response.data.data.user));
      //   yield put(actions.setRegisterFormStatus(false));
    }
  } catch (error) {
    console.log("ERROR REGISTER: ", error);
  }
}

export function* authUserLogin(action) {
  const authData = {
    email: action.email,
    password: action.password,
  };

  try {
    const response = yield call(api.loginUser, authData);

    if (response.errors) {
      console.log("Login errors response: ", response.errors);
      yield put(
        actions.setErrorAuthMessages("", "", response.errors.data.data.message)
      );
    } else {
      console.log("Login user response: ", response.data);

      yield put(actions.setErrorAuthMessages("", "", ""));

      localStorage.setItem("token", response.data.data.access_token);

      yield put(actions.fetchHomeNews());
      yield put(actions.fetchHomeBooks());
      yield put(actions.fetchNews());
      yield put(actions.fetchBooks());

      yield put(actions.authSuccess(response.data.data.user));
      yield put(actions.setLoginFormStatus(false));
    }
  } catch (error) {
    console.log("ERROR REGISTER: ", error);
  }
}
