import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, NavLink } from "react-router-dom";
import "./singlepage.scss";
import { FiHeart } from "react-icons/fi";
import Carousel from "../carousel/carousel";
import dayjs from "dayjs";
import PrimaryButton from "../buttons/PrimaryButton";
import * as actions from "../../actions";

function Book() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isFav, setIsFav] = useState(false);
  const [isSub, setIsSub] = useState(false);
  const { favBooks } = useSelector((state) => state.favorites);
  const { subBooks } = useSelector((state) => state.subs);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(actions.fetchBooks());
    dispatch(actions.fetchSingleBook(id));

    console.log(id);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (favBooks) {
      setIsFav(favBooks.some((elem) => elem.id === parseInt(id)));
    }
  }, [favBooks, id]);

  useEffect(() => {
    if (subBooks) {
      setIsSub(subBooks.some((elem) => elem.id === parseInt(id)));
    }
  }, [subBooks, id]);

  const allBooksCategories = useSelector((state) => state.books);
  const book = useSelector((state) => state.singleBookObject);
  const [relatedBooks, setRelatedBooks] = useState([]);

  useEffect(() => {
    if (book && allBooksCategories !== "") {
      const temp = allBooksCategories.find(
        (elem) => elem.id === book.categories[0]
      );
      const filteredBooks = temp.books.filter(
        (elem) => elem.id !== parseInt(id)
      );
      setRelatedBooks(filteredBooks);
    }
    // eslint-disable-next-line
  }, [book, allBooksCategories]);

  const formatDate = (date) => {
    let formatDate = dayjs(date).format("D[.] MMM YYYY[.]");
    return formatDate;
  };

  return (
    <div className="singlepage">
      <div className="single_row">
        <div className="single_box">
          {/* <div className="single_content_mobile">
            <div className="single_title_mobile">
              {book.name}
            </div>
          </div> */}
          <div className="single_img">
            <img
              src={
                book
                  ? book.photo_url
                  : "https://www.stazeibogaze.info/wp-content/uploads/2016/08/default-placeholder.png"
              }
              alt="Book"
            />
          </div>
          <div className="single_content">
            <div className="single_title">
              {book.name}
              <div
                onClick={() => {
                  if (isFav) {
                    dispatch(actions.removeFavorite(parseInt(id)));
                  } else {
                    dispatch(actions.addFavorite(parseInt(id)));
                  }
                }}
              >
                <FiHeart className={isFav ? "active" : ""} />
              </div>
            </div>
            <div className="single_date">{formatDate(book.created_at)}</div>
            <div className="single_description">{book.description}</div>
            <div className="single_buttons">
              {/* <PrimaryButton
                cart
                price={book.price}
                btnText="DODAJ U KORPU"
                onPress={() => {}}
              /> */}
              {!isSub ? (
                <PrimaryButton
                  price="3.024,00"
                  btnText="PRETPLATI SE"
                  onPress={() =>
                    dispatch(actions.addSub(parseInt(id), "Montly"))
                  }
                />
              ) : (
                <PrimaryButton
                  btnText="PROČITAJ"
                  onPress={() => alert("U izradi...")}
                />
              )}
            </div>
          </div>
          <div className="single_content_mobile">
            <div className="single_date_mobile">
              {formatDate(book.created_at)}
            </div>
            <div className="single_description_mobile">{book.description}</div>
            <div className="single_buttons_mobile">
              {/* <PrimaryButton
                cart
                price={book.price}
                btnText="DODAJ U KORPU"
                onPress={() => {}}
              /> */}
              {!isSub ? (
                <PrimaryButton
                  price="3.024,00"
                  btnText="PRETPLATI SE"
                  onPress={() =>
                    dispatch(actions.addSub(parseInt(id), "Montly"))
                  }
                />
              ) : (
                <PrimaryButton
                  btnText="PROČITAJ"
                  onPress={() => alert("U izradi...")}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="carousel_section news_carousel">
        <div className="carousel_title">SLICNA IZDANJA</div>
        {<Carousel type="books" data={relatedBooks} />}
        <div className="carousel_button">
          <NavLink to="/books">VIDI SVE KNJIGE</NavLink>
        </div>
      </div>
    </div>
  );
}

export default Book;
