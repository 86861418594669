import React from "react";
import Slider from "react-slick";

import "./homeSlider.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../../assets/images/slider_img.png";
import img2 from "../../assets/images/slider_img2.png";

function HomeSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: true,
  };

  return (
    <div className="slider_holder">
      <Slider {...settings}>
        <div className="slider_item">
          <div
            className="slider_item_bg"
            style={{ backgroundImage: `url(${img1})` }}
          ></div>
        </div>
        <div className="slider_item">
          <div
            className="slider_item_bg"
            style={{ backgroundImage: `url(${img2})` }}
          ></div>
        </div>
      </Slider>
      <div className="slider_app_buttons_holder">
        <div className="app_button apple">IOS APLIKACIJA</div>
        <div className="app_button android">ANDROID APLIKACIJA</div>
      </div>
    </div>
  );
}

export default HomeSlider;
