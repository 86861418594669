import React, { useState } from 'react';
//import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
//import { registerUser } from '../../api';
import * as actions from '../../actions';
import "./authForm.scss";

// TEST ACCOUNT: laliw30715@94jo.com , Testtest

function LoginForm() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const errorMessages = useSelector(state => state.authErrorMessages);

    const dispatch = useDispatch();

    const loginUser = () => {
        console.log("Username: ", username);
        console.log("Password: ", password);

        dispatch(actions.authLogin(username, password));
    }

    const closeForm = () => {
        dispatch(actions.setLoginFormStatus(false));
    }

    const openRegisterForm = () => {
        dispatch(actions.setRegisterFormStatus(true));
        dispatch(actions.setLoginFormStatus(false));
    }

    return (
        <div className="auth_form_holder login">
            <div className="auth_form_bg">
                <div className="close_button" onClick={() => closeForm()}></div>
                <div className="auth_form_cup"></div>
                <div className="auth_form">
                    <div className="title">Prijavite se</div>
                    <input placeholder="Email" type="text" onChange={(e) => setUsername(e.target.value)}></input>
                    <input placeholder="Lozinka" type="password" onChange={(e) => setPassword(e.target.value)}></input>

                    {
                        errorMessages.authMessage &&
                            <div className="auth_error_message">{errorMessages.authMessage}</div>
                    }

                    <div className="forgot_password">Zaboravili ste lozinku? Kliknite <span>ovde</span></div>
                    <button onClick={() => loginUser()}>Prijavi me</button>
                    <div className="register_row">Nemate nalog? <span onClick={() => openRegisterForm()}>Registrujte se</span></div>
                </div>
            </div>
        </div>
    )
}

export default LoginForm;