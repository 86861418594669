import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
//import Carousel from "../carousel/carousel";
import CategoriesSideBar from "../categoriesSideBar/categoriesSideBar";
import CategoriesMobile from "../categoriesMobile/categoriesMobile";
import * as actions from "../../actions";
import "./categoryPage.scss";

function NewsCategory(props) {
  const selectedCategoryNews = useSelector(
    (state) => state.selectedCategoryNews
  );
  const news = useSelector((state) => state.news);

  const dispatch = useDispatch();

  const goToSingle = (item) => {
    dispatch(actions.setSingleNews(item));
    dispatch(actions.fetchNewsEditions(item.id));
    props.history.push(`/newspaper/${item.id}`);
  };

  const { categoryName } = useParams();
  const catName = categoryName.split("-").join(" ");

  useEffect(() => {
    const cat = news.find((elem) => elem.name === catName);
    if (cat) {
      dispatch(actions.setSelectedCategoryNews(cat));
    }
    // eslint-disable-next-line
  }, [news, categoryName]);

  return (
    <>
      {news && <CategoriesMobile type="news" data={news} />}
      <div className="container category_page_holder">
        <div className="data_list_holder">
          <div className="category_name">{selectedCategoryNews.name}</div>
          <div className="data_list">
            {selectedCategoryNews &&
              selectedCategoryNews.news.map((item, index) => {
                if (item.edition !== null) {
                  return (
                    <div
                      key={index}
                      className="data_item"
                      onClick={() => goToSingle(item)}
                    >
                      <img src={item.edition.photo_url} alt="Item" />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
        <div className="category_sidebar">
          {news && <CategoriesSideBar type="news" data={news} />}
        </div>
      </div>
    </>
  );
}

export default NewsCategory;
