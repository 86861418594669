import React from "react";
import "./PrimaryButton.scss";

const PrimaryButton = ({ price, btnText, onPress, cart }) => {
  return (
    <div className={cart ? "button_box cart" : "button_box"}>
      {price && <div className="price">{price} RSD</div>}
      <div className="single_button" onClick={onPress}>
        {btnText}
      </div>
    </div>
  );
};

export default PrimaryButton;
