import React from "react";
import "./searchBox.scss";

function SearchBox({changed}) {
  return (
    <div className="search_box">
      <input placeholder="Pretraži" type="text" onChange={changed} />
      <div className="search_button"></div>
    </div>
  );
}

export default SearchBox;
