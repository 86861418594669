import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./categoriesMobile.scss";
import openListIcon from "../../assets/images/openList_icon.png";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions";

const CategoriesMobile = (props) => {
  const dispatch = useDispatch();

  const [listDisplayed, setListDisplayed] = useState(false);

  const toggleList = () => {
    setListDisplayed((prevState) => !prevState);
  };

  const openCategory = (category) => {
    let categoryName = category.name.split(" ").join("-");

    if (props.type === "news") {
      dispatch(actions.setSelectedCategoryNews(category));
      props.history.push(`/news/${categoryName}`);
    } else {
      dispatch(actions.setSelectedCategoryBooks(category));
      props.history.push(`/books/${categoryName}`);
    }
    toggleList();
  };

  return (
    <div className="container categories_mobile">
      <div className="categories_mobile_title" onClick={toggleList}>
        {props.type === "news" ? "KATEGORIJE E-NOVINA" : "KATEGORIJE E-KNJIGA"}
        <img
          src={openListIcon}
          className={listDisplayed ? "rotated-icon" : ""}
          alt="Icon"
        />
      </div>
      <div
        className={
          "category_mobile_list" + (listDisplayed ? " list_displayed" : "")
        }
      >
        {props.data.map((category, index) => (
          <div
            key={index}
            className="category_mobile_item"
            onClick={() => openCategory(category)}
          >
            {category.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(CategoriesMobile);
