import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CategoriesGrid from "../categoriesGrid/categoriesGrid";
import CategoriesSideBar from "../categoriesSideBar/categoriesSideBar";
import CategoriesMobile from "../categoriesMobile/categoriesMobile";
import "./news.scss";

function News() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const news = useSelector((state) => state.news);

  return (
    <>
      <CategoriesMobile type="news" data={news} />
      <div className="categories_page">
        <div className="categories_grid_holder">
          {news && <CategoriesGrid type="news" data={news} />}
        </div>
        <div className="categories_sidebar">
          <CategoriesSideBar type="news" data={news} />
        </div>
      </div>
    </>
  );
}

export default News;
