import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import "./fileReader.scss";
import * as actions from "../../actions";
import Loader from "../loader/Loader";

/*const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};*/

function FileReader(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const file = useSelector((state) => state.file);

  const dispatch = useDispatch();

  console.log("FILE: ", file);

  useEffect(() => {}, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoaded(true);
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const closeFileReadedr = () => {
    dispatch(actions.setfileReaderStatus(false));
  };

  const [readerHeight, setReaderHeight] = useState(window.innerHeight - 80);
  // eslint-disable-next-line
  const [readerWidth, setReaderWidth] = useState(window.innerWidth - 40);

  useEffect(() => {
    const updateLayout = () => {
      setReaderHeight(window.innerHeight - 80);
      setReaderWidth(window.innerWidth - 40);
    };
    window.addEventListener("resize", updateLayout);
    return () => {
      window.removeEventListener("resize", updateLayout);
    };
  });

  return (
    <div className="file_reader">
      <div className="close_button" onClick={() => closeFileReadedr()}></div>
      <div className="pdfWrapper">
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          options={{ workerSrc: "/pdf.worker.js" }}
          className="file"
          //options={options}
        >
          <Page
            pageNumber={pageNumber}
            className="pdfPage"
            height={readerHeight}
            // width={readerHeight > readerWidth ? readerWidth : ""}
            renderTextLayer={false}
            renderInteractiveForms={false}
          />
        </Document>
      </div>
      {loaded ? (
        <div className="page_row">
          <div className="page_button prev_page" onClick={() => prevPage()}>
            Prev
          </div>
          <div className="page_number">
            Page {pageNumber} of {numPages}
          </div>
          <div className="page_button next_page" onClick={() => nextPage()}>
            Next
          </div>
        </div>
      ) : (
        <Loader />
      )}
      {/* {!loaded && <Loader />} */}
    </div>
  );
}

export default FileReader;
