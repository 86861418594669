import axios from "axios";

let baseUrl = "http://admin.novine-online.net/api/";

const instance = () => {
  return axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

export const registerUser = async (data) => {
  try {
    const response = await axios.post(`${baseUrl}register`, data);
    return response;
  } catch (error) {
    console.log("ERROR REGISTER: ", error.response);
    return {
      errors: error.response,
    };
  }
};

export const loginUser = async (data) => {
  try {
    const response = await axios.post(`${baseUrl}login`, data);
    return response;
  } catch (error) {
    console.log("ERROR LOGIN: ", error.response);
    return {
      errors: error.response,
    };
  }
};

export const fetchNews = async () => {
  try {
    const response = await instance().get(`${baseUrl}news`);
    return response;
  } catch (error) {
    console.log("ERROR FETCH NEWS: ", error.response);
  }
};

export const fetchBooks = async () => {
  try {
    const response = await instance().get(`${baseUrl}books`);
    return response;
  } catch (error) {
    console.log("ERROR FETCH BOOKS: ", error.response);
  }
};

export const getReaderToken = async (data) => {
  try {
    const response = await instance().post(`${baseUrl}gain-reader-token`, data);
    return response;
  } catch (error) {
    console.log("ERROR GET READER TOKEN: ", error.response);
  }
};

export const getFile = async (token) => {
  try {
    const response = await instance().get(`${baseUrl}reader/${token}`);
    return response;
  } catch (error) {
    console.log("ERROR GET FILE: ", error.response);
  }
};

export const fetchHomeNews = async () => {
  try {
    const response = await instance().get(`${baseUrl}home-news`);
    return response;
  } catch (error) {
    console.log("ERROR FETCH HOME NEWS: ", error.response);
  }
};

export const fetchHomeBooks = async () => {
  try {
    const response = await instance().get(`${baseUrl}home-books`);
    return response;
  } catch (error) {
    console.log("ERROR FETCH HOME BOOKS: ", error.response);
  }
};

export const fetchNewsEditions = async (newsId) => {
  try {
    const response = await instance().get(`${baseUrl}all-editions/${newsId}`);
    return response;
  } catch (error) {
    console.log("ERROR FETCH NEWS EDITIONS: ", error.response);
  }
};

export const fetchSingleNews = async (newsId) => {
  try {
    const response = await instance().get(`${baseUrl}news/${newsId}`);
    return response;
  } catch (error) {
    console.log("ERROR FETCH SINGLE NEWS: ", error.response);
  }
};

export const fetchSingleBook = async (bookId) => {
  try {
    const response = await instance().get(`${baseUrl}book/${bookId}`);
    return response;
  } catch (error) {
    console.log("ERROR FETCH SINGLE BOOK: ", error.response);
  }
};

export const fetchFavorites = async () => {
  try {
    const response = await instance().get(`${baseUrl}favorites`);
    return response;
  } catch (error) {
    console.log("ERROR FETCH FAVORITES: ", error.response);
  }
};

export const fetchSubs = async () => {
  try {
    const response = await instance().get(`${baseUrl}subscribed`);
    return response;
  } catch (error) {
    console.log("ERROR FETCH SUBS: ", error.response);
  }
};

export const addFavorite = async (id) => {
  try {
    const response = await instance().post(`${baseUrl}favorite`, {
      item_id: id,
    });
    return response;
  } catch (error) {
    console.log("ERROR ADD FAVORITE: ", error.response);
  }
};

export const removeFavorite = async (id) => {
  try {
    const response = await instance().post(`${baseUrl}unfavorite`, {
      item_id: id,
    });
    return response;
  } catch (error) {
    console.log("ERROR REMOVE FAVORITE: ", error.response);
  }
};

export const addSub = async (id, duration) => {
  try {
    const response = await instance().post(`${baseUrl}subscribe`, {
      item_id: id,
      duration: duration,
    });
    return response;
  } catch (error) {
    console.log("ERROR ADD SUB: ", error.response);
  }
};
