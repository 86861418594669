import * as actionType from "../actions/actionTypes";

export const books = (state = "", action) => {
  switch (action.type) {
    case actionType.FETCH_BOOKS_SECCESS:
      return action.books;
    default:
      return state;
  }
};

export const homeBooks = (state = "", action) => {
  switch (action.type) {
    case actionType.FETCH_HOME_BOOKS_SECCESS:
      return action.books;
    default:
      return state;
  }
};

export const selectedCategoryBooks = (state = "", action) => {
  switch (action.type) {
    case actionType.SET_SELECTED_CATEGORY_BOOKS:
      return action.category;
    default:
      return state;
  }
};

export const singleBook = (state = "", action) => {
  switch (action.type) {
    case actionType.SET_SINGLE_BOOK:
      return action.book;
    default:
      return state;
  }
};

//TODO: CHANGE REDUCER NAME
export const singleBookObject = (state = "", action) => {
  switch (action.type) {
    case actionType.FETCH_SINGLE_BOOK_SUCCESS:
      return action.singleBook;
    default:
      return state;
  }
};
