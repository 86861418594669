import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./editionsMobileList.scss";
import openListIcon from "../../assets/images/openList_icon.png";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import SideBarItem from "../sideBarItem/SideBarItem";

const EditionsMobileList = (props) => {
  const [listDisplayed, setListDisplayed] = useState(false);
  // const { type, title, data, id } = props;
  const {title, id } = props;
  const newsEditions = useSelector((state) => state.newsEditions);

  const formatDate = (date) => {
    let formatDate = dayjs(date).format("D[.] MMM YYYY[.]");
    return formatDate;
  };

  const toggleList = () => {
    setListDisplayed((prevState) => !prevState);
  };

  // const openCategory = (category) => {
  //   let categoryName = category.name.split(" ").join("-");

  //   if (props.type === "news") {
  //     dispatch(actions.setSelectedCategoryNews(category));
  //     props.history.push(`/news/${categoryName}`);
  //   } else {
  //     dispatch(actions.setSelectedCategoryBooks(category));
  //     props.history.push(`/books/${categoryName}`);
  //   }
  //   toggleList();
  // };

  return (
    <div className="container editions_mobile">
      <div className="editions_mobile_title" onClick={toggleList}>
        {title}
        <img
          src={openListIcon}
          className={listDisplayed ? "rotated-icon" : ""}
          alt="Icon"
        />
      </div>
      <div
        className={
          "editions_mobile_list" + (listDisplayed ? " list_displayed" : "")
        }
      >
        {newsEditions &&
          newsEditions.editions.map((item, index) => (
            <SideBarItem
              newsId={id}
              key={index}
              id={item.id}
              photo_url={item.photo_url}
              name={newsEditions.news.name}
              date={formatDate(item.published_date)}
              description={newsEditions.news.description}
              price={newsEditions.news.price}
            />
          ))}
      </div>
    </div>
  );
};

export default withRouter(EditionsMobileList);
