import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CategoriesGrid from "../categoriesGrid/categoriesGrid";
import CategoriesSideBar from "../categoriesSideBar/categoriesSideBar";
import CategoriesMobile from "../categoriesMobile/categoriesMobile";

function Books(props) {
  const books = useSelector((state) => state.books);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {books && <CategoriesMobile type="books" data={books} />}
      <div className="categories_page">
        <div className="categories_grid_holder">
          {books && <CategoriesGrid type="books" data={books} />}
        </div>
        <div className="categories_sidebar">
          {books && <CategoriesSideBar type="books" data={books} />}
        </div>
      </div>
    </>
  );
}

export default Books;
