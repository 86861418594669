import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./Favorites.scss";
// import * as actions from "../../actions";

const Favorites = (props) => {
  // const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(1);

  // useEffect(() => {
  //   dispatch(actions.getFavorites());
  // }, []);

  const { favNews, favBooks } = useSelector((state) => state.favorites);

  return (
    <div className="Favorites">
      <h1>Omiljeni proizvodi</h1>
      <div className="shared_toggle">
        <div
          className={"single_toggle" + (activeTab === 1 ? " active" : "")}
          onClick={() => setActiveTab(1)}
        >
          NOVINE
        </div>
        <div
          className={"single_toggle" + (activeTab === 2 ? " active" : "")}
          onClick={() => setActiveTab(2)}
        >
          KNJIGE
        </div>
      </div>
      <div className="shared_grid">
        {activeTab === 1 &&
          favNews &&
          favNews.length > 0 &&
          favNews.map((item) => (
            <div
              key={item.id}
              className="shared_grid_item"
              onClick={() => props.history.push(`/newspaper/${item.id}`)}
            >
              <img
                src={item.edition && item.edition.photo_url}
                alt="Favorite product"
              />
            </div>
          ))}
        {activeTab === 2 &&
          favBooks &&
          favBooks.length > 0 &&
          favBooks.map((item) => (
            <div
              key={item.id}
              className="shared_grid_item"
              onClick={() => props.history.push(`/book/${item.id}`)}
            >
              <img src={item.photo_url} alt="Favorite product" />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Favorites;
