import * as actionTypes from "./actionTypes";

export const fetchNews = () => {
  return {
    type: actionTypes.FETCH_NEWS,
  };
};

export const fetchNewsSuccess = (news) => {
  return {
    type: actionTypes.FETCH_NEWS_SECCESS,
    news,
  };
};

export const fetchNewsEditions = (newsId) => {
  return {
    type: actionTypes.FETCH_NEWS_EDITIONS,
    newsId,
  };
};

export const fetchNewsEditionsSuccess = (newsEditions) => {
  return {
    type: actionTypes.FETCH_NEWS_EDITIONS_SECCESS,
    newsEditions,
  };
};

export const fetchHomeNews = () => {
  return {
    type: actionTypes.FETCH_HOME_NEWS,
  };
};

export const fetchHomeNewsSuccess = (news) => {
  return {
    type: actionTypes.FETCH_HOME_NEWS_SECCESS,
    news,
  };
};

export const setSelectedCategoryNews = (category) => {
  return {
    type: actionTypes.SET_SELECTED_CATEGORY_NEWS,
    category,
  };
};

export const getReaderToken = (fileId, fileType) => {
  return {
    type: actionTypes.GET_READER_TOKEN,
    file_id: fileId,
    file_type: fileType,
  };
};

export const setSingleNews = (news) => {
  return {
    type: actionTypes.SET_SINGLE_NEWS,
    news,
  };
};

export const fetchSingleNews = (newsId) => {
  return {
    type: actionTypes.FETCH_SINGLE_NEWS,
    newsId,
  };
};

export const fetchSingleNewsSuccess = (singleNews) => {
  return {
    type: actionTypes.FETCH_SINGLE_NEWS_SUCCESS,
    singleNews,
  };
};
