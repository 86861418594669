import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from "redux";
import { Provider } from 'react-redux';
import createSagaMiddleware from "redux-saga";
import rootReducer from './reducers';
import { watchSaga } from "./sagas";

/*const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;
*/
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(watchSaga);

const app = (
  <Provider store={store}>
      <BrowserRouter><App /></BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));


