import * as actionTypes from "./actionTypes";

export const setfileReaderStatus = (status) => {
    return {
        type: actionTypes.SET_FILE_READER_STATUS,
        status
    }
}

export const setFile = (file) => {
    return {
        type: actionTypes.SET_FILE,
        file
    }
}