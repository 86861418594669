import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import HomeSlider from "../slider/homeSlider";
import Carousel from "../carousel/carousel";
import cupIcon from "../../assets/images/cup_icon.png";
import iphoneImg from "../../assets/images/iphone.png";
import androidImg from "../../assets/images/android.png";
import creditIcon from "../../assets/images/coin.png";
import vaucherIcon from "../../assets/images/vaucher.png";
import androidIcon from "../../assets/images/android_icon.png";
import iosIcon from "../../assets/images/apple_icon.png";
//import bookIcon from "../../assets/images/book.png";
import "./home.scss";

//import novostiNaslovna from "../../assets/images/novosti_naslovna.png";

function Home() {
  const news = useSelector((state) => state.homeNews);
  const books = useSelector((state) => state.homeBooks);
  const [tabIndex, setTabIndex] = useState(0);
  // const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      <div className="home">
        <HomeSlider />
        <div className="carousel_section news_carousel">
          <div className="carousel_title">DNEVNE NOVINE</div>
          {news && <Carousel type="news" data={news} />}
          <div className="carousel_button">
            <NavLink to="/news">VIDI SVE NOVINE</NavLink>
          </div>
        </div>
        <div className="carousel_section books_carousel">
          <div className="carousel_title">KNJIGE</div>
          {books && <Carousel type="books" data={books} />}
          <div className="carousel_button">
            <NavLink to="/books">VIDI SVE KNJIGE</NavLink>
          </div>
        </div>
        <div className="container promo_section">
          <div className="promo_item">
            <div className="promo_icon">
              <img src={cupIcon} alt="Icon" />
            </div>
            <div className="propmo_title">BESPLATNE NOVINE</div>
          </div>
          <div className="promo_item">
            <div className="promo_icon">
              <img src={cupIcon} alt="Icon" />
            </div>
            <div className="propmo_title">BESPLATNE KNJIGE</div>
          </div>
          <div className="promo_item">
            <div className="promo_icon">
              <img src={cupIcon} alt="Icon" />
            </div>
            <div className="propmo_title">PROMO PRIMERCI</div>
          </div>
        </div>
        <div className="tab_section_wrapper">
          <div className="container tab_section">
            <div className="tab_list">
              <div
                className={"tab_item" + (tabIndex === 0 ? " active" : "")}
                onClick={() => setTabIndex(0)}
              >
                NAJNOVIJE
              </div>
              <div
                className={"tab_item" + (tabIndex === 1 ? " active" : "")}
                onClick={() => setTabIndex(1)}
              >
                NAJPRODAVANIJE
              </div>
            </div>
            {tabIndex === 0
              ? books && <Carousel type="books" data={books} />
              : books && <Carousel type="books" data={books} />}
            <div className="carousel_button">
              <NavLink to="/books">VIDI SVE KNJIGE</NavLink>
            </div>
          </div>
        </div>
        <div className="container phone_section">
          <div className="phone_box">
            <div className="phone_bg">
              <div className="phone_item">
                <div className="phone_img">
                  <img src={iphoneImg} alt="iPhone" />
                  <div className="phone_button iphone_button">
                    <img src={iosIcon} alt="Icon" />
                    <span>APLIKACIJA</span>
                  </div>
                </div>
              </div>
              <div className="phone_item">
                <div className="phone_img">
                  <img src={androidImg} alt="Android" />
                  <div className="phone_button android_button">
                    <img src={androidIcon} alt="Android Icon" />
                    <span>APLIKACIJA</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="credit_buttons_box">
            <div className="button credit">
              <img src={creditIcon} alt="Credit icon" />
              <div className="button_label">DOPUNI KREDIT</div>
            </div>
            <div className="button vaucher">
              <img src={vaucherIcon} alt="Vaucher icon" />
              <div className="button_label">PROMO VAUČER</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
