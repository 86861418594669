import * as actionType from "../actions/actionTypes";

export const favorites = (state = {}, action) => {
  switch (action.type) {
    case actionType.SET_FAVORITES:
      return {
        favNews: action.favNews,
        favBooks: action.favBooks,
      };
    default:
      return state;
  }
};

export const subs = (state = {}, action) => {
  switch (action.type) {
    case actionType.SET_SUBS:
      return {
        subNews: action.subNews,
        subBooks: action.subBooks,
      };
    default:
      return state;
  }
};
