import * as actionType from "../actions/actionTypes";

export const news = (state = [], action) => {
  switch (action.type) {
    case actionType.FETCH_NEWS_SECCESS:
      return action.news;
    default:
      return state;
  }
};

export const homeNews = (state = [], action) => {
  switch (action.type) {
    case actionType.FETCH_HOME_NEWS_SECCESS:
      return action.news;
    default:
      return state;
  }
};

export const newsEditions = (state = "", action) => {
  switch (action.type) {
    case actionType.FETCH_NEWS_EDITIONS_SECCESS:
      return action.newsEditions;
    default:
      return state;
  }
};

export const selectedCategoryNews = (state = "", action) => {
  switch (action.type) {
    case actionType.SET_SELECTED_CATEGORY_NEWS:
      return action.category;
    default:
      return state;
  }
};

export const singleNews = (state = "", action) => {
  switch (action.type) {
    case actionType.SET_SINGLE_NEWS:
      return action.news;
    default:
      return state;
  }
};

//TODO: CHANGE REDUCER NAME
export const singleNewsObject = (state = "", action) => {
  switch (action.type) {
    case actionType.FETCH_SINGLE_NEWS_SUCCESS:
      return action.singleNews;
    default:
      return state;
  }
};
