import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import "./header.scss";
import logo from "../../assets/images/logo.png";
import Navbar from "../navbar/navbar";
import SearchBox from "../searchBox/searchBox";
import AccountBar from "../accountBar/accountBar";
import * as utility from "../../helpers/utility";
// import cartIconDark from "../../assets/images/cart_icon_dark.png";
import accountIcon from "../../assets/images/account_icon.png";
import newsIcon from "../../assets/images/news_icon.png";
import bookIcon from "../../assets/images/book_icon.png";
import { AiFillSave } from "react-icons/ai";
import * as actions from "../../actions";
import Heart from "../Heart/Heart";

function Header(props) {
  const dispatch = useDispatch();
  const [navOpened, setNavOpened] = useState(false);

  const toggleNav = () => {
    setNavOpened((prevState) => !prevState);
  };

  const goToLoginFornm = () => {
    //props.history.push("/login");
    dispatch(actions.setLoginFormStatus(true));
  };

  const handleAccountClick = () => {
    if (!utility.isAuth()) {
      goToLoginFornm();
      toggleNav();
    }
  };

  return (
    <>
      <div className={"header" + (utility.isAuth() ? " logedIn" : "")}>
        <div className="container header_holder">
          <div className="header_logo">
            <div className="logo" onClick={() => props.history.push("/")}>
              <img src={logo} alt="Logo" />
            </div>
            <div className="search_box_container">
              <SearchBox />
            </div>
          </div>
          <div className="nav_account_box">
            <Navbar />
            <AccountBar />
          </div>
        </div>
        <div className="mobile_nav" onClick={toggleNav}>
          <div className="hamburger">
            <div className={"line" + (navOpened ? " active-line" : "")} />
            <div className={"line" + (navOpened ? " active-line" : "")} />
            {/* <div className="line" /> */}
          </div>
        </div>
      </div>
      <div className={"nav-links" + (navOpened ? " active-links" : "")}>
        <div className="listItem search">
          <div style={{ cursor: "default" }}>
            <SearchBox className={navOpened ? "mobile_search" : ""} />
          </div>
        </div>
        <div className="listItem newsListItem">
          <div className="imgWrapper">
            <img src={newsIcon} alt="News Icon" />
          </div>
          <NavLink className="nav-link" to="/news" onClick={toggleNav}>
            NOVINE
          </NavLink>
        </div>
        <div className="listItem bookListItem">
          <div className="imgWrapper">
            <img src={bookIcon} alt="Book Icon" />
          </div>
          <NavLink className="nav-link" to="/books" onClick={toggleNav}>
            KNJIGE
          </NavLink>
        </div>
        {utility.isAuth() && (
          <>
            {/* <div className="listItem cartListItem">
              <div className="imgWrapper">
                <div className="notification_mobile">1</div>
                <img src={cartIconDark} alt="Cart Icon" />
              </div>
              KORPA
            </div> */}
            <div className="listItem favoritesListItem">
              <div className="imgWrapper">
                <Heart for="dropdownMenuOption" />
              </div>
              <NavLink className="nav-link" to="/favorites" onClick={toggleNav}>
                OMILJENO
              </NavLink>
            </div>
            <div className="listItem favoritesListItem">
              <div className="imgWrapper">
                <AiFillSave color="#676767" size={30} />
              </div>
              <NavLink className="nav-link" to="/favorites" onClick={toggleNav}>
                PRETPLATE
              </NavLink>
            </div>
          </>
        )}
        <div className="listItem accountListItem" onClick={handleAccountClick}>
          <div className="imgWrapper">
            <img src={accountIcon} alt="Account Icon" />
          </div>
          {utility.isAuth() ? "NALOG" : "PRIJAVI SE"}
        </div>
      </div>
    </>
  );
}

export default withRouter(Header);
