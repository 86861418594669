import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CategoriesSideBar from "../categoriesSideBar/categoriesSideBar";
import CategoriesMobile from "../categoriesMobile/categoriesMobile";
import * as actions from "../../actions";
import "./categoryPage.scss";

function BookCategory(props) {
  const selectedCategoryBooks = useSelector(
    (state) => state.selectedCategoryBooks
  );
  const books = useSelector((state) => state.books);

  const dispatch = useDispatch();

  const goToSingle = (item) => {
    dispatch(actions.setSingleBook(item));
    props.history.push(`/book/${item.id}`);
  };

  const { categoryName } = useParams();
  const catName = categoryName.split("-").join(" ");

  useEffect(() => {
    if (books) {
      const cat = books.find((elem) => elem.name === catName);
      if (cat) {
        dispatch(actions.setSelectedCategoryBooks(cat));
      }
    }
    // eslint-disable-next-line
  }, [books, categoryName]);

  console.log(books);

  return (
    <>
      {books && <CategoriesMobile type="books" data={books} />}
      <div className="container category_page_holder">
        <div className="data_list_holder">
          <div className="category_name">{selectedCategoryBooks.name}</div>
          <div className="data_list">
            {selectedCategoryBooks &&
              selectedCategoryBooks.books.map((item, index) => (
                <div
                  key={index}
                  className="data_item"
                  onClick={() => goToSingle(item)}
                >
                  <img src={item.photo_url} alt="Item" />
                </div>
              ))}
          </div>
        </div>
        <div className="category_sidebar">
          {books && <CategoriesSideBar type="books" data={books} />}
        </div>
      </div>
    </>
  );
}

export default BookCategory;
