export const AUTH_REGISTER = "AUTH_REGISTER";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const FETCH_NEWS = "FETCH_NEWS";
export const FETCH_NEWS_SECCESS = "FETCH_NEWS_SECCESS";
export const FETCH_BOOKS = "FETCH_BOOKS";
export const FETCH_BOOKS_SECCESS = "FETCH_BOOKS_SECCESS";
export const LOGIN_FORM_STATUS = "LOGIN_FORM_STATUS";
export const REGISTER_FORM_STATUS = "REGISTER_FORM_STATUS";
export const SET_SELECTED_CATEGORY_NEWS = "SET_SELECTED_CATEGORY_NEWS";
export const SET_SELECTED_CATEGORY_BOOKS = "SET_SELECTED_CATEGORY_BOOKS";
export const GET_READER_TOKEN = "GET_READER_TOKEN";
export const SET_FILE_READER_STATUS = "SET_FILE_READER_STATUS";
export const SET_FILE = "SET_FILE";
export const FETCH_HOME_NEWS = "FETCH_HOME_NEWS";
export const FETCH_HOME_NEWS_SECCESS = "FETCH_HOME_NEWS_SECCESS";
export const FETCH_HOME_BOOKS = "FETCH_HOME_BOOKS";
export const FETCH_HOME_BOOKS_SECCESS = "FETCH_HOME_BOOKS_SECCESS";
export const SET_SINGLE_NEWS = "SET_SINGLE_NEWS";
export const SET_SINGLE_BOOK = "SET_SINGLE_BOOK";
export const SET_AUTH_ERROR_MESSAGES = "SET_AUTH_ERROR_MESSAGES";
export const FETCH_NEWS_EDITIONS = "FETCH_NEWS_EDITIONS";
export const FETCH_NEWS_EDITIONS_SECCESS = "FETCH_NEWS_EDITIONS_SECCESS";
export const FETCH_SINGLE_NEWS = "FETCH_SINGLE_NEWS";
export const FETCH_SINGLE_NEWS_SUCCESS = "FETCH_SINGLE_NEWS_SUCCESS";
export const FETCH_SINGLE_BOOK = "FETCH_SINGLE_BOOK";
export const FETCH_SINGLE_BOOK_SUCCESS = "FETCH_SINGLE_BOOK_SUCCESS";
export const GET_FAVORITES = "GET_FAVORITES";
export const SET_FAVORITES = "SET_FAVORITES";
export const ADD_FAVORITE = "ADD_FAVORITE";
export const REMOVE_FAVORITE = "REMOVE_FAVORITE";
export const GET_SUBS = "GET_SUBS";
export const SET_SUBS = "SET_SUBS";
export const ADD_SUB = "ADD_SUB";
export const REMOVE_SUB = "REMOVE_SUB";
