import * as actionTypes from "./actionTypes";

export const authRegister = (email, password, password_confirm) => {
    return {
        type: actionTypes.AUTH_REGISTER,
        email,
        password,
        password_confirm
    };
};

export const authLogin = (email, password) => {
    return {
        type: actionTypes.AUTH_LOGIN,
        email,
        password
    };
};

export const authSuccess = (user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        user
    };
};

export const setLoginFormStatus = (status) => {
    return {
        type: actionTypes.LOGIN_FORM_STATUS,
        status
    }
}

export const setRegisterFormStatus = (status) => {
    return {
        type: actionTypes.REGISTER_FORM_STATUS,
        status
    }
}

export const setErrorAuthMessages = (usernameMessage, passwordMessage, authMessage) => {
    return {
        type: actionTypes.SET_AUTH_ERROR_MESSAGES,
        usernameMessage,
        passwordMessage,
        authMessage
    }
}