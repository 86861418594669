import React from "react";
//import { useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Slider from "react-slick";
//import * as actions from "../../actions";

import "./carousel.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Carousel(props) {
  //const dispatch = useDispatch();

  const getInfiniteCarousel = () => {
    if (
      props.location.pathname === "/news" ||
      props.location.pathname === "/books"
    ) {
      return props.data.length > 4 ? true : false;
    } else {
      return props.data.length > 5 ? true : false;
    }
  };

  const getSlidesToShow = () => {
    if (
      props.location.pathname === "/news" ||
      props.location.pathname === "/books"
    ) {
      return 4;
    } else {
      return 5;
    }
  };

  const settings = {
    dots: false,
    infinite: getInfiniteCarousel(),
    speed: 500,
    slidesToShow: getSlidesToShow(),
    slidesToScroll: 1,
    fade: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container carousel_holder">
      <Slider {...settings}>
        {props.data.map((item, index) => (
          <Link
            key={index}
            className="slider_item"
            to={
              props.type === "news"
                ? `/newspaper/${item.id}`
                : `/book/${item.id}`
            }
          >
            {/* <div
            key={index}
            className="slider_item"
            onClick={() => goToSingle(item)}
          > */}
            <img
              src={
                props.type === "news"
                  ? item.edition && item.edition.photo_url
                  : item.photo_url
              }
              alt="News"
            />
            {/* </div> */}
          </Link>
        ))}
      </Slider>
    </div>
  );
}

export default withRouter(Carousel);
