import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";

import footerLogo from "../../assets/images/footer_logo.png";
import payPalIcon from "../../assets/images/paypal_icon.png";
import masterCardIcon from "../../assets/images/mastercard_icon.png";
import visaIcon from "../../assets/images/visa_icon.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer_overlay">
        <div className="container">
          <div className="footer_col links">
            <div className="footer_col_title">MAPA SAJTA</div>
            <div className="footer_col_item">
              <Link to={""}>O nama</Link>
            </div>
            <div className="footer_col_item">
              <Link to={""}>Pregled izdavača</Link>
            </div>
            <div className="footer_col_item">
              <Link to={""}>Načini plaćanja</Link>
            </div>
            <div className="footer_col_item">
              <Link to={""}>Pravilnik</Link>
            </div>
            <div className="footer_col_item">
              <Link to={""}>Pomoć</Link>
            </div>
          </div>
          <div className="footer_col links">
            <div className="footer_col_title">MAPA SAJTA</div>
            <div className="footer_col_item">
              <Link to={""}>O nama</Link>
            </div>
            <div className="footer_col_item">
              <Link to={""}>Pregled izdavača</Link>
            </div>
            <div className="footer_col_item">
              <Link to={""}>Načini plaćanja</Link>
            </div>
            <div className="footer_col_item">
              <Link to={""}>Pravilnik</Link>
            </div>
            <div className="footer_col_item">
              <Link to={""}>Pomoć</Link>
            </div>
          </div>
          <div className="footer_col logo">
            <div className="footer_logo">
              <img src={footerLogo} alt="Logo"/>
            </div>
            <div className="cards_row">
              <img src={payPalIcon} alt="PayPal Icon"/>
              <img src={masterCardIcon} alt="MasterCard Icon"/>
              <img src={visaIcon} alt="Visa Icon"/>
            </div>
          </div>
        </div>
        <div className="footer_bottom">Novine online © All rigths reserved</div>
      </div>
    </div>
  );
}

export default Footer;
