import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import "./App.css";
import Header from "./components/header/header";
import Home from "./components/home/home";
import Footer from "./components/footer/footer";
import RegisterForm from "./components/auth/registerForm";
import LoginForm from "./components/auth/loginForm";
import Newspaper from "./components/singlePages/newspaper";
import Book from "./components/singlePages/book";
import Edition from "./components/singlePages/edition";
import News from "./components/news/news";
import Books from "./components/books/books";
import Favorites from "./components/favorites/Favorites";
import Subs from "./components/subs/Subs";
import BookCategory from "./components/categoryPage/bookCategory";
import NewsCategory from "./components/categoryPage/newsCategory";
import FileReader from "./components/fileReader/fileReader";
// import Search from "./components/search/Search";
import * as actions from "./actions";
import * as utility from "./helpers/utility";

function App(props) {
  // const user = useSelector((state) => state.user);
  // const news = useSelector((state) => state.news);
  // const books = useSelector((state) => state.books);
  const loginFormStatus = useSelector((state) => state.loginFormStatus);
  const registerFormStatus = useSelector((state) => state.registerFormStatus);
  const fileReaderStatus = useSelector((state) => state.fileReaderStatus);

  const dispatch = useDispatch();

  //const user = {name: "Milan"};

  useEffect(() => {
    //dispatch(actions.authRegister("sssss@gmail.com", "123456", "123456"));
    dispatch(actions.fetchHomeNews());
    dispatch(actions.fetchHomeBooks());
    dispatch(actions.fetchNews());
    dispatch(actions.fetchBooks());
    dispatch(actions.getFavorites());
    dispatch(actions.getSubs());
    // eslint-disable-next-line
  }, []);

  //console.log("USER: ", user);
  //console.log("NEWS: ", news);
  //console.log("BOOKS: ", books);

  return (
    <div className="App">
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/register" component={RegisterForm} />
        {/* <Route path='/login' component={LoginForm} /> */}
        <Route path="/newspaper/:id" component={Newspaper} />
        <Route path="/book/:id" component={Book} />
        <Route path="/edition/:newsId/:id" component={Edition} />
        <Route exact path="/news" component={News} />
        <Route exact path="/books" component={Books} />
        {utility.isAuth() ? (
          <>
            <Route exact path="/favorites" component={Favorites} />
            <Route exact path="/subs" component={Subs} />
          </>
        ) : null}
        <Route path="/books/:categoryName" component={BookCategory} />
        <Route path="/news/:categoryName" component={NewsCategory} />
        {/* <Route path="/search" component={Search} /> */}
        <Redirect to="/" />
      </Switch>
      <Footer />

      {loginFormStatus && <LoginForm />}

      {registerFormStatus && <RegisterForm />}

      {fileReaderStatus && <FileReader />}
    </div>
  );
}

export default withRouter(App);
