import React from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Carousel from "../carousel/carousel";
import * as actions from "../../actions";

import "./categoriesGrid.scss";

function CategoriesGrid(props) {
  //console.log("CategoriesGrid Data: ", props.data);

  const dispatch = useDispatch();

  const openCategory = (category) => {
    let categoryName = category.name.split(" ").join("-");

    if (props.location.pathname === "/news") {
      dispatch(actions.setSelectedCategoryNews(category));
      props.history.push(`/news/${category.name}`);
    } else {
      dispatch(actions.setSelectedCategoryBooks(category));
      props.history.push(`/books/${categoryName}`);
    }
  };

  return (
    <div className="categories_grid">
      {props.data.map((category, index) => (
        <div key={index} className="category_row">
          <div className="category_header">
            <div className="category_title">{category.name}</div>
            {/* <div className="show_all" onClick={() => openCategory(category)}>vidi sve</div> */}
          </div>
          <div className="category_carousel">
            {props.location.pathname === "/news" ? (
              <Carousel type="news" data={category.news} />
            ) : (
              <Carousel type="books" data={category.books} />
            )}
          </div>
          <div className="show_all" onClick={() => openCategory(category)}>
            <div className="show_all_btn">
              VIDI SVE {props.type === "news" ? "NOVINE" : "KNJIGE"}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default withRouter(CategoriesGrid);
