import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { registerUser } from '../../api';
import { BsCheckCircle } from "react-icons/bs";
import * as actions from "../../actions";

function RegisterForm(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const errorMessages = useSelector((state) => state.authErrorMessages);
  const user = useSelector((state) => state.user);

  const [usernameErr, setUserNameErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [confPassErr, setConfPassErr] = useState(false);
  const [termsErr, setTermsErr] = useState(false);

  const dispatch = useDispatch();

  const checkPasswordValidation = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{7,}$/;
    const isValid = regex.test(password) && password.length >= 7;
    return isValid;
  };

  const registerUser = async () => {
    if (
      username !== "" &&
      checkPasswordValidation(password) &&
      password === confirmPassword &&
      isChecked
    ) {
      dispatch(actions.authRegister(username, password, confirmPassword));
    } else {
      !username ? setUserNameErr(true) : setUserNameErr(false);
      !checkPasswordValidation(password)
        ? setPasswordErr(true)
        : setPasswordErr(false);
      password !== confirmPassword
        ? setConfPassErr(true)
        : setConfPassErr(false);
      !isChecked ? setTermsErr(true) : setTermsErr(false);
    }
  };

  const closeForm = () => {
    dispatch(actions.setRegisterFormStatus(false));
  };

  const openLoginForm = () => {
    dispatch(actions.setLoginFormStatus(true));
    dispatch(actions.setRegisterFormStatus(false));
  };

  return (
    <div className="auth_form_holder register">
      <div className="auth_form_bg">
        <div className="close_button" onClick={() => closeForm()}></div>
        <div className="auth_form_cup"></div>
        {!user ? (
          <div className="auth_form">
            <div className="title">Registrujte se</div>
            <input
              placeholder="Email"
              type="text"
              onChange={(e) => setUsername(e.target.value)}
            ></input>

            {errorMessages.usernameMessage &&
              errorMessages.usernameMessage.map((item, index) => (
                <div key={index} className="auth_error_message">
                  {item}
                </div>
              ))}
            {usernameErr && (
              <div className="auth_error_message">Morate uneti email</div>
            )}

            <input
              placeholder="Lozinka"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            ></input>

            {passwordErr && (
              <div className="auth_error_message">
                Lozinka mora imati min. 7 karaktera, jedno veliko slovo i jedan
                broj
              </div>
            )}

            <input
              placeholder="Potvrdite lozinku"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></input>
            {confPassErr && (
              <div className="auth_error_message">Lozinke moraju biti iste</div>
            )}
            <button onClick={() => registerUser()}>Registruj me</button>
            <div className="check_box_row">
              <input type="checkbox" onClick={() => setIsChecked(!isChecked)} />
              <div className="checkbox_label">Slazem se sa pravilnikom. *</div>
            </div>
            {termsErr && (
              <div className="auth_error_message terms">
                Morate se složiti sa pravilnikom
              </div>
            )}
            <div className="register_row">
              Vec imate nalog?{" "}
              <span onClick={() => openLoginForm()}>Prijavite se</span>
            </div>
          </div>
        ) : (
          <div className="registration_sucess">
            <BsCheckCircle color="#00ff00" />
            <p className="success_text">Uspešno ste se registrovali</p>
            <button
              className="success_text"
              onClick={() => dispatch(actions.setRegisterFormStatus(false))}
            >
              OK
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default RegisterForm;
