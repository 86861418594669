import { put, call } from "redux-saga/effects";

import * as actions from "../actions/index";
import * as api from "../api";

export function* fetchNews(action) {
  try {
    const response = yield call(api.fetchNews);
    //console.log("Fetch news response: ", response.data);
    yield put(actions.fetchNewsSuccess(response.data.data.categories));
  } catch (error) {
    console.log("ERROR FETCH NEWS: ", error);
  }
}

export function* fetchBooks(action) {
  try {
    const response = yield call(api.fetchBooks);
    //console.log("Fetch books response: ", response.data);
    yield put(actions.fetchBooksSuccess(response.data.data.categories));
  } catch (error) {
    console.log("ERROR FETCH BOOKS: ", error);
  }
}

export function* getReaderToken(action) {
  const data = {
    file_id: action.file_id,
    file_type: action.file_type,
  };

  try {
    const response = yield call(api.getReaderToken, data);
    console.log("SUCCESS GET READER TOKEN: ", response.data.reader_token);
    //yield put(actions.fetchBooksSuccess(response.data.data.categories));

    //const fileResponse = yield call(api.getFile, response.data.reader_token);
    //console.log("SUCCESS GET FILE: ", fileResponse.data);

    let fileUrl = `http://admin.novine-online.net/api/reader/${response.data.reader_token}`;

    // yield put(actions.setFile(fileResponse.data));
    yield put(actions.setFile(fileUrl));
    yield put(actions.setfileReaderStatus(true));
  } catch (error) {
    console.log("ERROR GET READER TOKEN: ", error);
  }
}

export function* fetchHomeNews() {
  try {
    const response = yield call(api.fetchHomeNews);
    // console.log("Fetch home news response: ", response.data.data);
    yield put(actions.fetchHomeNewsSuccess(response.data.data));
  } catch (error) {
    console.log("ERROR FETCH HOME NEWS: ", error);
  }
}

export function* fetchHomeBooks() {
  try {
    const response = yield call(api.fetchHomeBooks);
    //console.log("Fetch home books response: ", response.data);
    yield put(actions.fetchHomeBooksSuccess(response.data.data));
  } catch (error) {
    console.log("ERROR FETCH HOME BOOKS: ", error);
  }
}

export function* fetchNewsEditions(action) {
  try {
    const response = yield call(api.fetchNewsEditions, action.newsId);
    console.log("Fetch news editions response: ", response.data);
    yield put(actions.fetchNewsEditionsSuccess(response.data.data));
  } catch (error) {
    console.log("ERROR FETCH NEWS EDITIONS: ", error);
  }
}

export function* fetchSingleNews(action) {
  try {
    const response = yield call(api.fetchSingleNews, action.newsId);
    console.log("Fetch single news response: ", response.data.data);
    yield put(actions.fetchSingleNewsSuccess(response.data.data));
  } catch (error) {
    console.log("ERROR FETCH SINGLE NEWS: ", error);
  }
}

export function* fetchSingleBook(action) {
  try {
    const response = yield call(api.fetchSingleBook, action.bookId);
    console.log("Fetch single book response: ", response.data.data);
    yield put(actions.fetchSingleBookSuccess(response.data.data));
  } catch (error) {
    console.log("ERROR FETCH SINGLE BOOK: ", error);
  }
}

export function* fetchFavorites() {
  try {
    const response = yield call(api.fetchFavorites);
    //console.log("Fetch home books response: ", response.data);
    yield put(
      actions.setFavorites(response.data.data.news, response.data.data.books)
    );
  } catch (error) {
    console.log("ERROR FETCH FAVORITES: ", error);
  }
}

export function* fetchSubs() {
  try {
    const response = yield call(api.fetchSubs);
    //console.log("Fetch home books response: ", response.data);
    yield put(
      actions.setSubs(response.data.data.news, response.data.data.books)
    );
  } catch (error) {
    console.log("ERROR FETCH FAVORITES: ", error);
  }
}

export function* addFavorite(action) {
  try {
    const response = yield call(api.addFavorite, action.id);

    console.log("SUCCESS ADD FAVORITE: ", response.data);
    yield put(actions.getFavorites());
  } catch (error) {
    console.log("ERROR ADD FAVORITE: ", error);
  }
}

export function* removeFavorite(action) {
  try {
    const response = yield call(api.removeFavorite, action.id);

    console.log("SUCCESS REMOVE FAVORITE: ", response.data);
    yield put(actions.getFavorites());
  } catch (error) {
    console.log("ERROR REMOVE FAVORITE: ", error);
  }
}

export function* addSub(action) {
  try {
    const response = yield call(api.addSub, action.id, action.duration);

    console.log("SUCCESS ADD SUB: ", response.data);
    yield put(actions.getSubs());
  } catch (error) {
    console.log("ERROR ADD SUB: ", error);
  }
}
