import React from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./accountBar.scss";
import * as utility from "../../helpers/utility";
// import cartIcon from "../../assets/images/cart_icon.png";
import logedAccountIcon from "../../assets/images/loged_account_icon.png";
import accountIcon from "../../assets/images/account_icon.png";
import * as actions from "../../actions";

function AccountBar(props) {
  const dispatch = useDispatch();

  const goToLoginFornm = () => {
    //props.history.push("/login");
    dispatch(actions.setLoginFormStatus(true));
  };

  return (
    <div className="account_bar">
      {utility.isAuth() ? (
        <div className="loged_account">
          {/* <div className="icon cart_icon">
            <div className="notification">1</div>
            <img src={cartIcon} alt="Cart Icon"/>
          </div> */}
          {/* <NavLink to="/favorites">
            <Heart for="menuOption" />
          </NavLink> */}
          <div className="icon account_icon">
            <img src={logedAccountIcon} alt="Logged Icon" />
          </div>
        </div>
      ) : (
        <div className="login_button" onClick={() => goToLoginFornm()}>
          <div className="login_label">Tvoj nalog</div>
          <img src={accountIcon} alt="Account Icon" />
        </div>
      )}
    </div>
  );
}

export default withRouter(AccountBar);
