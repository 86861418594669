import * as actionTypes from "./actionTypes";

export const fetchBooks = () => {
  return {
    type: actionTypes.FETCH_BOOKS,
  };
};

export const fetchBooksSuccess = (books) => {
  return {
    type: actionTypes.FETCH_BOOKS_SECCESS,
    books,
  };
};

export const fetchHomeBooks = () => {
  return {
    type: actionTypes.FETCH_HOME_BOOKS,
  };
};

export const fetchHomeBooksSuccess = (books) => {
  return {
    type: actionTypes.FETCH_HOME_BOOKS_SECCESS,
    books,
  };
};

export const setSelectedCategoryBooks = (category) => {
  return {
    type: actionTypes.SET_SELECTED_CATEGORY_BOOKS,
    category,
  };
};

export const setSingleBook = (book) => {
  return {
    type: actionTypes.SET_SINGLE_BOOK,
    book,
  };
};

export const fetchSingleBook = (bookId) => {
  return {
    type: actionTypes.FETCH_SINGLE_BOOK,
    bookId,
  };
};

export const fetchSingleBookSuccess = (singleBook) => {
  return {
    type: actionTypes.FETCH_SINGLE_BOOK_SUCCESS,
    singleBook,
  };
};
