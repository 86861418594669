import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "./singlepage.scss";
import SideBarList from "../sideBarList/sideBarList";
import * as actions from "../../actions";
import dayjs from "dayjs";
import PrimaryButton from "../buttons/PrimaryButton";
import EditionsMobileList from "../editionsMobileList/editionsMobileList";
import Heart from "../Heart/Heart";

function Edition() {
  const dispatch = useDispatch();
  const { newsId, id } = useParams();

  const newsEditions = useSelector((state) => state.newsEditions);
  const [edition, setEdition] = useState();

  useEffect(() => {
    dispatch(actions.fetchSingleNews(newsId));
    // eslint-disable-next-line
  }, [newsId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (newsEditions) {
      const temp = newsEditions.editions.filter(
        (elem) => elem.id === parseInt(id)
      );
      setEdition(temp);
    }
  }, [newsEditions, id]);

  useEffect(() => {
    dispatch(actions.fetchNewsEditions(newsId));
    // eslint-disable-next-line
  }, [newsId]);

  const news = useSelector((state) => state.singleNewsObject);
  // Favorites
  const [isAddedToFavorites, setAddedToFavorites] = useState(false);

  const formatDate = (date) => {
    let formatDate = dayjs(date).format("D[.] MMM YYYY[.]");
    return formatDate;
  };

  const readNews = () => {
    dispatch(actions.getReaderToken(id, "edition"));
  };

  return (
    <>
      <EditionsMobileList title="Arhiva" type="news" id={news.id} />
      <div className="singlepage">
        {edition && (
          <div className="single_row">
            <div className="single_box">
              <div className="single_content_mobile">
                <div className="single_title_mobile">
                  {news.name}&nbsp;
                  <Heart
                    added={isAddedToFavorites}
                    for="addingToFavorites"
                    onClickEvent={setAddedToFavorites}
                  />
                </div>
              </div>
              <div className="single_img">
                <img
                  src={
                    edition
                      ? edition[0].photo_url
                      : "https://www.stazeibogaze.info/wp-content/uploads/2016/08/default-placeholder.png"
                  }
                  alt="Edition"
                />
              </div>
              <div className="single_content">
                <div className="single_title">
                  {news.name}
                  {/* <Heart
                    added={isAddedToFavorites}
                    for="addingToFavorites"
                    onClickEvent={setAddedToFavorites}
                  /> */}
                </div>
                <div className="single_date">
                  {formatDate(edition.published_date)}
                </div>
                <div className="single_description">{news.description}</div>
                <div className="single_buttons">
                  {/* <PrimaryButton
                    cart
                    price={news.price}
                    btnText="DODAJ U KORPU"
                    onPress={() => {}}
                  /> */}
                  <PrimaryButton
                    price="3.024,00"
                    btnText="PRETPLATI SE"
                    onPress={() => {}}
                  />
                  <PrimaryButton btnText="PROČITAJ" onPress={readNews} />
                </div>
              </div>
              <div className="single_content_mobile">
                <div className="single_date_mobile">
                  {formatDate(edition.published_date)}
                </div>
                <div className="single_description_mobile">
                  {news.description}
                </div>
                <div className="single_buttons_mobile">
                  {/* <PrimaryButton
                    cart
                    price={news.price}
                    btnText="DODAJ U KORPU"
                    onPress={() => {}}
                  /> */}
                  <PrimaryButton
                    price="3.024,00"
                    btnText="PRETPLATI SE"
                    onPress={() => {}}
                  />
                  <PrimaryButton btnText="PROČITAJ" onPress={readNews} />
                </div>
              </div>
            </div>
            <SideBarList title="Arhiva" type="news" id={news.id} />
          </div>
        )}
        {/* <div className="carousel_section news_carousel">
        <div className="carousel_title">SLICNA IZDANJA</div>
        {<Carousel type="news" data={relatedNews} />}
        <div className="carousel_button">
          <NavLink to="/news">VIDI SVE NOVINE</NavLink>
        </div>
      </div> */}
      </div>
    </>
  );
}

export default Edition;
