import * as actionType from '../actions/actionTypes';

export const fileReaderStatus = (state = false, action) => {
    switch (action.type) {
        case actionType.SET_FILE_READER_STATUS:
            return action.status;
        default:
            return state;
    }
    
}

export const file = (state = "", action) => {
    switch (action.type) {
        case actionType.SET_FILE:
            return action.file;
        default:
            return state;
    }
    
}

