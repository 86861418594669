import React from 'react';

import "./Heart.scss";

import {ReactComponent as Heart} from "../../assets/favorites/heart.svg";

const HeartComponent = (props) => {

  const classes = ["Heart"];

  if (props.for === "addingToFavorites") {
    if (props.added === false) {
      classes.push("empty");
    } else {
      classes.push("fill");
    }
    return <Heart className={classes.join(' ')} onClick={() => props.onClickEvent(!props.added)}/>;
  } else if (props.for === "menuOption") {
    classes.push("menuOption");
    return <Heart className={classes.join(' ')}/>;
  } else {
    classes.push("dropdownMenuOption");
    return <Heart className={classes.join(' ')}/>;
  }
}

export default HeartComponent;