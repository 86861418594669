import React from "react";
import { NavLink } from "react-router-dom";
import * as utility from "../../helpers/utility";
import "./navbar.scss";

function Navbar() {
  return (
    <div className="navbar">
      <NavLink to="/news">Novine</NavLink>
      <NavLink to="/books">Knjige</NavLink>
      {utility.isAuth() && (
        <>
          <NavLink to="/favorites">Omiljeno</NavLink>
          <NavLink to="/subs">Pretplate</NavLink>
        </>
      )}
    </div>
  );
}

export default Navbar;
