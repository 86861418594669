import React from "react";
import "./SideBarItem.scss";
import { Link } from "react-router-dom";

const SideBarItem = (props) => {
  return (
    <Link to={`/edition/${props.newsId}/${props.id}`}>
      <div className="list_item_wrap">
        <div className="list_item">
          <div className="list_item_img">
            <img src={props.photo_url} alt="List item"/>
          </div>
          <div className="list_item_content">
            <div className="title">{props.name}</div>
            <div className="date">{props.date}</div>
            <div className="description">{props.description}</div>
            <div className="price">{props.price}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SideBarItem;
