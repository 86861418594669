import React from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions";

import "./categoriesSideBar.scss";

function CategoriesSideBar(props) {
  const dispatch = useDispatch();

  const openCategory = (category) => {
    let categoryName = category.name.split(" ").join("-");

    if (props.type === "news") {
      dispatch(actions.setSelectedCategoryNews(category));
      props.history.push(`/news/${categoryName}`);
    } else {
      dispatch(actions.setSelectedCategoryBooks(category));
      props.history.push(`/books/${categoryName}`);
    }
  };

  return (
    <div className="categories_sidebar_holder">
      <div className="categories_sidebar_title">
        {props.type === "news" ? "Kategorije E-novina" : "Kategorije E-knjiga"}
      </div>
      <div className="categories_list">
        {props.data.map((category, index) => (
          <div
            key={index}
            className="category_item"
            onClick={() => openCategory(category)}
          >
            <div className="category_name">{category.name}</div>
            <div className="arrows"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default withRouter(CategoriesSideBar);
