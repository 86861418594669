import React from "react";
import "./sideBarList.scss";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import SideBarItem from "../sideBarItem/SideBarItem";

function SideBarList(props) {
  //const { type, title, data, id } = props;
  const {title, id } = props;
  const newsEditions = useSelector((state) => state.newsEditions);

  const formatDate = (date) => {
    let formatDate = dayjs(date).format("D[.] MMM YYYY[.]");
    return formatDate;
  };

  return (
    <div className="sidebar_list_holder">
      <div className="sidebar_title">{title}</div>
      <div className="list_wrap">
        {newsEditions &&
          newsEditions.editions.map((item, index) => (
            <SideBarItem
              newsId={id}
              key={index}
              id={item.id}
              photo_url={item.photo_url}
              name={newsEditions.news.name}
              date={formatDate(item.published_date)}
              description={newsEditions.news.description}
              price={newsEditions.news.price}
            />
          ))}
      </div>
    </div>
  );
}

export default SideBarList;
