import * as actionTypes from "./actionTypes";

export const getFavorites = () => {
  return {
    type: actionTypes.GET_FAVORITES,
  };
};

export const setFavorites = (favNews, favBooks) => {
  return {
    type: actionTypes.SET_FAVORITES,
    favNews,
    favBooks,
  };
};

export const addFavorite = (id) => {
  return {
    type: actionTypes.ADD_FAVORITE,
    id,
  };
};

export const removeFavorite = (id) => {
  return {
    type: actionTypes.REMOVE_FAVORITE,
    id,
  };
};

export const getSubs = () => {
  return {
    type: actionTypes.GET_SUBS,
  };
};

export const setSubs = (subNews, subBooks) => {
  return {
    type: actionTypes.SET_SUBS,
    subNews,
    subBooks,
  };
};

export const addSub = (id, duration) => {
  return {
    type: actionTypes.ADD_SUB,
    id,
    duration,
  };
};

export const removeSub = (id) => {
  return {
    type: actionTypes.REMOVE_SUB,
    id,
  };
};
