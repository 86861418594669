import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { FiHeart } from "react-icons/fi";
import "./singlepage.scss";
import Carousel from "../carousel/carousel";
import SideBarList from "../sideBarList/sideBarList";
import * as actions from "../../actions";
import dayjs from "dayjs";
import PrimaryButton from "../buttons/PrimaryButton";
import EditionsMobileList from "../editionsMobileList/editionsMobileList";

function Newspaper() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isFav, setIsFav] = useState(false);
  const [isSub, setIsSub] = useState(false);
  const { favNews } = useSelector((state) => state.favorites);
  const { subNews } = useSelector((state) => state.subs);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(actions.fetchNews());
    dispatch(actions.fetchSingleNews(id));
    dispatch(actions.fetchNewsEditions(id));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (favNews) {
      setIsFav(favNews.some((elem) => elem.id === parseInt(id)));
    }
    // eslint-disable-next-line
  }, [favNews, id]);

  useEffect(() => {
    if (subNews) {
      setIsSub(subNews.some((elem) => elem.id === parseInt(id)));
    }
    // eslint-disable-next-line
  }, [subNews, id]);

  const allNewsCategories = useSelector((state) => state.news);
  const news = useSelector((state) => state.singleNewsObject);
  const [relatedNews, setRelatedNews] = useState([]);

  useEffect(() => {
    if (news && allNewsCategories !== "") {
      const temp = allNewsCategories.find(
        (elem) => elem.id === news.categories[0]
      );
      if (temp) {
        const filteredNews = temp.news.filter(
          (elem) => elem.id !== parseInt(id)
        );
        setRelatedNews(filteredNews);
      }
    }
    // eslint-disable-next-line
  }, [news, allNewsCategories]);

  const formatDate = (date) => {
    let formatDate = dayjs(date).format("D[.] MMM YYYY[.]");
    return formatDate;
  };

  const readNews = () => {
    dispatch(actions.getReaderToken(id, "news"));
  };

  return (
    <>
      <EditionsMobileList title="Arhiva" type="news" id={news.id} />
      <div className="singlepage">
        <div className="single_row">
          <div className="single_box">
            {/* <div className="single_content_mobile">
              <div className="single_title_mobile">{news.name}</div>
              <FiHeart color="red" />
            </div> */}
            <div className="single_img">
              <img
                alt="Edition"
                src={
                  news.edition
                    ? news.edition.photo_url
                    : "https://www.stazeibogaze.info/wp-content/uploads/2016/08/default-placeholder.png"
                }
              />
            </div>
            <div className="single_content">
              <div className="single_title">
                <p>{news.name}</p>
                <div
                  onClick={() => {
                    if (isFav) {
                      dispatch(actions.removeFavorite(parseInt(id)));
                    } else {
                      dispatch(actions.addFavorite(parseInt(id)));
                    }
                  }}
                >
                  <FiHeart className={isFav ? "active" : ""} />
                </div>
              </div>
              <div className="single_date">{formatDate(news.created_at)}</div>
              <div className="single_description">{news.description}</div>
              <div className="single_buttons">
                {/* <PrimaryButton
                  cart
                  price={news.price}
                  btnText="DODAJ U KORPU"
                  onPress={() => {}}
                /> */}
                {!isSub ? (
                  <PrimaryButton
                    price="3.024,00"
                    btnText="PRETPLATI SE"
                    onPress={() =>
                      dispatch(actions.addSub(parseInt(id), "Montly"))
                    }
                  />
                ) : (
                  <PrimaryButton btnText="PROČITAJ" onPress={readNews} />
                )}
              </div>
            </div>
            <div className="single_content_mobile">
              <div className="single_date_mobile">
                {formatDate(news.created_at)}
              </div>
              <div className="single_description_mobile">
                {news.description}
              </div>
              <div className="single_buttons_mobile">
                {/* <PrimaryButton
                  cart
                  price={news.price}
                  btnText="DODAJ U KORPU"
                  onPress={() => {}}
                /> */}
                {!isSub ? (
                  <PrimaryButton
                    price="3.024,00"
                    btnText="PRETPLATI SE"
                    onPress={() =>
                      dispatch(actions.addSub(parseInt(id), "Montly"))
                    }
                  />
                ) : (
                  <PrimaryButton btnText="PROČITAJ" onPress={readNews} />
                )}
              </div>
            </div>
          </div>
          <SideBarList title="Arhiva" type="news" id={news.id} />
        </div>
        <div className="carousel_section news_carousel">
          <div className="carousel_title">SLICNA IZDANJA</div>
          {<Carousel type="news" data={relatedNews} />}
          <div className="carousel_button">
            <NavLink to="/news">VIDI SVE NOVINE</NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default Newspaper;
