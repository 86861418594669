import React from "react";
import "./Loader.scss";
import loader from "../../assets/images/loader.gif";

const Loader = (props) => {
  return (
    <div className={props.fullPage ? "loader fullPage" : "loader"}>
      <img src={loader} alt="Loader"/>
    </div>
  );
};

export default Loader;
