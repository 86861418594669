import * as actionType from "../actions/actionTypes";

export const user = (state = null, action) => {
  switch (action.type) {
    case actionType.AUTH_SUCCESS:
      return action.user;
    default:
      return state;
  }
};

export const loginFormStatus = (state = false, action) => {
  switch (action.type) {
    case actionType.LOGIN_FORM_STATUS:
      return action.status;
    default:
      return state;
  }
};

export const registerFormStatus = (state = false, action) => {
  switch (action.type) {
    case actionType.REGISTER_FORM_STATUS:
      return action.status;
    default:
      return state;
  }
};

export const authErrorMessages = (
  state = { usernameMessage: "", passwordMessage: "", authMessage: "" },
  action
) => {
  switch (action.type) {
    case actionType.SET_AUTH_ERROR_MESSAGES:
      return {
        usernameMessage: action.usernameMessage,
        passwordMessage: action.passwordMessage,
        authMessage: action.authMessage,
      };
    default:
      return state;
  }
};
